/* Đặt biến CSS cho màu sắc */
:root {
  --gradient-cam: linear-gradient(135deg, #ff9800, #f57c00);
  --gradient-cam-hover: linear-gradient(135deg, #e65100, #ef6c00);
  --gradient-cam-active: linear-gradient(135deg, #d84315, #ff5722);

  --gradient-xanh: linear-gradient(135deg, #4caf50, #388e3c);
  --gradient-xanh-hover: linear-gradient(135deg, #2e7d32, #1b5e20);
  --gradient-xanh-active: linear-gradient(135deg, #1b5e20, #2e7d32);

  --shadow-color: rgba(0, 0, 0, 0.4);
  --light-hover: rgba(255, 255, 255, 0.15);
  --border-hover: rgba(255, 255, 255, 0.2);
}

/* Font-face và cài đặt chung */
@font-face {
  font-family: 'UTMEdwardianB';
  src: url('./assets/fonts/UTM-EdwardianB.ttf') format('truetype');
}

body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: linear-gradient(to right, #4b134f, #c94b4b);
  font-family: Inter, 'Segoe UI', sans-serif;
}

/* Flexbox utilities */
.flex { display: flex; }
.flex-column { flex-direction: column; }
.justify-center { justify-content: center; }
.align-center { align-items: center; }

/* Input row styling */
.input-row {
  display: flex;
  justify-content: center;
}

.input-row > .row-name {
  padding: 20px 0;
  width: 600px;
}

/* Input text field styling */
#id_input_name {
  /* font-family: 'UTMEdwardianB'; */
  outline: none;
  padding: 0 20px;
  font-size: 30px;
  margin-right: 5px;
  width: 80%;
  border: none;
  border-radius: 100px;
  box-shadow: 0 20px 30px -10px rgb(38, 57, 77);
}

/* Button common styles */
.upload_btn,
.download_btn {
  width: fit-content;
  padding: 0.7rem 1.2rem;  /* Giảm padding một chút để cân đối với font-size */
  font-size: 16px;  /* Giảm kích thước chữ */
  color: #fff;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 8px 20px var(--shadow-color);
  transition: all 0.4s ease;
  display: inline-flex;  /* Sử dụng inline-flex để đảm bảo nội dung trên một dòng */
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  white-space: nowrap; /* Đảm bảo nội dung không bị xuống dòng */
}

/* Upload button specific styles */
.upload_btn {
  /* background: var(--gradient-cam); */
  background: linear-gradient(to right, #C6426E, #642B73);
}

.upload_btn:hover {
  background: var(--gradient-cam-hover);
  box-shadow: 0 12px 24px var(--shadow-color);
  transform: translateY(-3px);
}

.upload_btn:active {
  background: var(--gradient-cam-active);
  transform: translateY(-1px);
  box-shadow: 0 5px 15px var(--shadow-color);
}

/* Download button specific styles */
.download_btn {
  background: var(--gradient-xanh);
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: bold;
}

.download_btn:hover {
  background: var(--gradient-xanh-hover);
  box-shadow: 0 12px 24px var(--shadow-color);
  transform: translateY(-3px);
}

.download_btn:active {
  background: var(--gradient-xanh-active);
  transform: translateY(-1px);
  box-shadow: 0 5px 15px var(--shadow-color);
}

/* Icon spacing for both buttons */
.upload_btn i,
.download_btn i {
  margin-right: 8px;
}

/* Hover effect lighting and border */
.upload_btn::before,
.download_btn::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: var(--light-hover);
  pointer-events: none;
  transition: transform 0.6s ease;
  transform: rotate(45deg) translateX(-300%) translateY(-300%);
}

.upload_btn:hover::before,
.download_btn:hover::before {
  transform: rotate(45deg) translateX(300%) translateY(300%);
}

.upload_btn::after,
.download_btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  border: 2px solid var(--border-hover);
  opacity: 0;
  transition: opacity 0.4s ease;
}

.upload_btn:hover::after,
.download_btn:hover::after {
  opacity: 1;
}

/* Canvas area styling */
.canvas-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Canvas styling */
#id_main_canvas {
  width: 100%;
  height: auto;
  box-shadow: 0 54px 55px rgba(0, 0, 0, 0.25), 
              0 -12px 30px rgba(0, 0, 0, 0.12), 
              0 4px 6px rgba(0, 0, 0, 0.12), 
              0 12px 13px rgba(0, 0, 0, 0.17), 
              0 -3px 5px rgba(0, 0, 0, 0.09);
}

/* Horizontal slider styling */
.horizontal {
  margin-top: 35px;
}

#id_width_pos {
  width: 801px;
  margin-bottom: 10px;
}

/* Labels styling */
#label-zoom, #label-width, #label-height {
  font-size: 15px;
  text-transform: uppercase;
  color: #fff;
}

/* Vertical slider styling */
.vertical_silder {
  height: 100%;
  -webkit-appearance: slider-vertical;
  margin-bottom: 10px;
}

/* Media Queries */
@media (max-width: 3000px) {
  .canvas-row > #id_main_canvas { width: 100% !important; }
  #id_width_pos { width: 1070px; }
}

@media (max-width: 1920.1px) {
  .canvas-row > #id_main_canvas { width: 60% !important; }
  #id_width_pos { width: 801px; }
}

@media (max-width: 1399.98px) {
  .canvas-row > #id_main_canvas { width: 60% !important; }
}

@media (max-width: 1199.98px) {
  .canvas-row {
      flex-direction: column;
  }
  .canvas-row > #id_main_canvas {
      width: 100% !important;
      order: 0;
  }
  .canvas-row > .left {
      margin-top: 2rem;
      order: 1;
  }
  .canvas-row > .right {
      margin-top: 2rem;
      order: 2;
  }
  .vertical_silder, #id_width_pos {
      width: 75%;
  }
  .horizontal {
      width: 100%;
      margin-top: 2rem;
  }
  .download-btn > #download {
      margin-top: 2rem;
  }
}

@media (max-width: 575.98px) {
  .container {
      padding-bottom: 1rem;
  }
  .canvas-row {
      flex-direction: column;
  }
  .canvas-row > #id_main_canvas {
      width: 100% !important;
      order: 0;
  }
  .canvas-row > .left, .canvas-row > .right {
      margin-top: 1rem;
  }
  .canvas-row > .left {
      order: 1;
  }
  .canvas-row > .right {
      order: 2;
  }
  .vertical_silder, #id_width_pos {
      width: 75%;
  }
  .horizontal {
      width: 100%;
      margin-top: 1rem;
  }
  .download-btn > #download {
      margin-top: 1rem;
  }
}
